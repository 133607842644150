import * as React from "react"
import Layout from "../components/layout";
import VideoBanner from "../components/home/video-banner";
import {StatsSection} from "../components/home/stats-section";
import HighlightsSection from "../components/home/highlights-section";
import ShortcutCollection from "../components/home/shortcut-collection";
import {TestimonialsSection} from "../components/home/testimonials-section";
import {PrincipalQuoteSection} from "../components/home/principal-quote-section";
import Consumer from "../context";
import {SEO} from "../components/seo";


const IndexPage = () => {
  return (
    <Layout>
        <Consumer>
            {({ data, set }) => (
                (data.category === 'home') ? console.log(data.category) :  set({ category: 'home' })
            )}
        </Consumer>

        <VideoBanner />
        <PrincipalQuoteSection />
        <StatsSection  />
        <HighlightsSection />
        <TestimonialsSection />
        <ShortcutCollection />
    </Layout>
  )
}

export default IndexPage

export const Head = () => (
    <SEO />
)
