import * as React from "react"
import {Link} from "gatsby";

import {HeroIcon} from "../HeroIcon";

import {EyeDropperIcon} from "@heroicons/react/24/outline";

interface Highlight {
    title: string
    desc: string
    icon: string
}

interface HighLightsParams {
    highlights: Highlight []
}

let highlights: Highlight[] = [
    {
        title: '研究与创新',
        desc: '高威大学研究和创新的目的是造福人类、社会和经济。我们的员工拥有创造性思维和协作性方法。我们的地点是独特的，位于欧洲边缘充满活力又能够连接全球的地区。',
        icon: 'LightBulbIcon'
    },
    {
        title: '商业与行业',
        desc: '高威大学致力于推动大学和行业之间的联系。做为一所企业、研究和学习机构，我们通过集结伙伴，增加研究资源网络以及其他合作形式与世界各地的合作伙伴通力合作，促进战略性的全球参与能力。',
        icon: 'CubeTransparentIcon'
    },
    {
        title: '国际医疗技术中心的核心',
        desc: '高威是欧洲最重要的医疗技术中心之一，而高威大学是这个生态系统的核心。世界十大医疗技术公司有八家在高威设立了实体机构。高威现在是国际公认的医疗技术中心，与旧金山、波士顿、明尼阿波利斯、新加坡和柏林齐名。',
        icon: 'EyeDropperIcon'
    },
]


const HighlightsSection = () => {
    return (
        <section className="body-font bg-galway-maroon text-white">
            <div className="container px-5 py-24 mx-auto">
                <div className="text-center mb-20">
                    {/*main title*/}
                    <h1 className="sm:text-3xl text-2xl font-medium title-font mb-4">
                        教学理念
                    </h1>
                    <p className="font-sans text-base leading-relaxed xl:w-2/4 lg:w-3/4 mx-auto">
                        高威大学严谨而创新的教学理念在国际上享有盛誉，大学的许多课程在国际排名上名列前茅。例如在最新的QS世界排名中，酒店管理和旅游专业排名前150名，考古专业排名前200名，英语语言类排名前150名，护理专业排名前150名，法律专业排名前200名…
                    </p>
                    <p className="font-sans text-base leading-relaxed xl:w-2/4 lg:w-3/4 mx-auto">
                        在秉承严谨创新教学理念的同时，高威大学也非常注重学生的实践能力，此外每一门课程的老师都认真负责，尽自己最大能力帮助每一位学生。这些也得到了所有国际学生的一致认可和好评。
                    </p>
                    <div className="flex mt-6 justify-center">
                        <div className="w-full h-1 bg-white inline-flex"></div>
                    </div>
                </div>
                <div className="flex flex-wrap sm:-m-4 -mx-4 -mb-10 -mt-4 md:space-y-0 space-y-6">
                    {
                        highlights.map((item) => (
                            <div className="p-4 w-full md:w-1/3 flex flex-col text-center items-center">
                                <div className="w-20 h-20 inline-flex items-center justify-center rounded-full bg-white text-galway-maroon mb-5 flex-shrink-0">
                                    <HeroIcon icon={item.icon} color='text-galway-maroon' size={12} outline />
                                </div>
                                <div className="flex-grow">
                                    <h2 className="text-lg title-font font-medium mb-3">{item.title}</h2>
                                    <p className="leading-relaxed text-base">
                                        {item.desc}
                                    </p>
                                </div>
                            </div>
                        ))
                    }
                </div>
                {/*@ts-ignore*/}
                <Link to='/application/apply'>
                    <button className="flex mx-auto mt-16 text-white bg-galway-deep-maroon border-0 py-2 px-8 focus:outline-none hover:bg-magenta rounded text-lg">
                        现在申请
                    </button>
                </Link>
            </div>
        </section>
    );
}

export default HighlightsSection