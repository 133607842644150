import React, { useState, useEffect } from "react";
import BannerVideo from "../../videos/720-15s.mp4";
import LazyLoad from 'react-lazyload'

let subs = [
    "欢迎访问爱尔兰高威大学",
    "全球排名前2%的大学",
    "世界领先的研究密集型高校",
    "超过96%的学生在毕业后就业或继续深造",
    "提供国际游学和实习的机会",
]

const Timer = () => {
    const [seconds, setSeconds] = useState(0);

    useEffect(() => {
        let interval = setInterval(() => {
            setSeconds(seconds => seconds + 1);
        }, 1000);

        return () => clearInterval(interval);
    }, [seconds]);

    return (
        <h1 className="text-center text-white backdrop-brightness-75 text-3xl md:text-5xl xl:text-6xl p-3">
            {subs[Math.floor(seconds/(60/subs.length))%subs.length]}
        </h1>
    );
};

const VideoBanner = () => {
    return (
        // @ts-ignore
        <LazyLoad once={true} resize={true} className="relative flex items-center justify-center w-full h-screen overflow-hidden">
            {/*galway-maroon tint 5%*/}
            <div className="relative z-50">
                <Timer />
            </div>
            {/*bg-galway-maroon opacity-5 for the tint*/}
            <div className="absolute w-full h-full top-0 left-0 z-20"></div>
            {/*subtitles*/}

            {/*<LazyLoad className="absolute flex w-full h-full top-0 left-0">*/}
            <video autoPlay loop muted playsInline className="absolute z-10 w-auto min-w-full min-h-full max-w-none">
                <source src={BannerVideo} type="video/mp4"/>
                对不起，您的浏览器不支持网页内视频播放
            </video>
            {/*</LazyLoad>*/}
        </LazyLoad>
    );
}

export default VideoBanner;
