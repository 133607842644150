import * as React from 'react'

interface ImageBoxParams {
    img_url: string
}

const ImageBox = ({img_url}: ImageBoxParams) => {
    return (
        <div className="container flex mx-auto lg:w-1/2 h-full">
            <img alt="feature" className="border-l-[24px] border-l-galway-maroon object-cover object-center"
                 src={img_url} />
        </div>
    )
}

export default ImageBox