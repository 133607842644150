import React from "react"
import Statistics, {Stat} from "../statistics";

export const StatsSection = () => {

    const stats: Stat[] = [
        {
            key: '96%',
            item: '毕业生在毕业6个月后就业或选择继续深造',
            icon: 'BriefcaseIcon'
        },
        {
            key: '年度大学',
            item: '《星期日泰晤士报》2022年',
            icon: 'StarIcon'
        },
        {
            key: '前2%',
            item: '全球大学排名',
            icon: 'ArrowTrendingUpIcon'
        },
        {
            key: '4000名',
            item: '国际学生',
            icon: 'AcademicCapIcon'
        },
        {
            key: '110个',
            item: '不同的国家',
            icon: 'GlobeAsiaAustraliaIcon'
        },
        {
            key: '10万',
            item: '校友遍布世界各地',
            icon: 'UserGroupIcon'
        },
        {
            key: '175年',
            item: '优秀领先的教学经验',
            icon: 'BuildingLibraryIcon'
        },
    ]

    return (
        <section className="bg-gray-100 pb-12">
            <Statistics stats={stats} />
        </section>
    )
}