import * as React from 'react'
import ImageBox from "../image-box";
import {HeroIcon} from "../HeroIcon";

import IconBuildingImg from "../../img/building/icon_building_1.webp"

interface Shortcut {
    title: string
    desc: string
    icon: string
    href: string
}


const ShortcutCollection = () => {

    const shortcuts: Shortcut[] = [
        {
            title: '录取条件',
            desc: '入学要求因课程而异，请点击了解详细的课程学术和语言要求等信息。',
            icon: 'KeyIcon',
            href: '/application/apply'
        },
        {
            title: '奖学金',
            desc: '高威大学致力于吸引来自世界各地的高素质学生。因此我们为中国留学生提供一系列基于成绩的丰厚奖学金。对中国学生的奖学金金额从1000欧元到全额奖学金不等。',
            icon: 'AcademicCapIcon',
            href: '/application/scholarship'
        },
        {
            title: '住宿',
            desc: '高威大学提供校内与校外住宿的不同选择，学生们可以根据自己的需求选择适合的类型。',
            icon: 'BuildingOfficeIcon',
            href: '/life/accommodation'
        }
    ]

    return (
        <section className="body-font">
            <div className="container px-5 py-24 mx-auto flex flex-wrap">
                <ImageBox img_url={IconBuildingImg} />
                <div className="flex flex-col flex-wrap lg:py-6 -mb-10 lg:w-1/2 lg:pl-12 lg:text-left text-center">
                    {
                        shortcuts.map((shortcut) => (
                            <div className="flex flex-col mb-10 lg:items-start items-center">
                                <div className="w-12 h-12 inline-flex items-center justify-center rounded-full bg-galway-maroon text-white mb-5">
                                    <HeroIcon icon={shortcut.icon} size={6} color="text-white" outline />
                                </div>
                                <div className="flex-grow">
                                    <h2 className="text-gray-900 text-lg title-font font-medium mb-3">
                                        {shortcut.title}
                                    </h2>
                                    <p className="leading-relaxed text-base">
                                        {shortcut.desc}
                                    </p>
                                    <a href={shortcut.href} className="mt-3 text-magenta inline-flex items-center">详情
                                        <svg fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round"
                                             strokeWidth="2" className="w-4 h-4 ml-2" viewBox="0 0 24 24">
                                            <path d="M5 12h14M12 5l7 7-7 7"></path>
                                        </svg>
                                    </a>
                                </div>
                            </div>
                        ))
                    }
                </div>
            </div>
        </section>
    )
}

export default ShortcutCollection