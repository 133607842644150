import React from "react"

import StudentPortraitQinLiu from '../../img/people/qin_liu.webp'
import StudentPortraitDanShan from '../../img/people/dan_shan.webp'
import Testimonial from "../testimonial"

interface TestimonialParams {
    name: string
    title: string
    quote: string
    photo: string
}

export const TestimonialsSection = () => {

    let testimonials: TestimonialParams[] = [
        {
            name: '单丹',
            title: '现就读于临床医学专业，大二',
            quote: '最吸引我的地方是在这里学医的国际生素质都很高，世界上最难之一的执业医师执照考试 - USMLE Step1&2通过率均超过了百分之90，并且高威大学School of Medicine跟世界各国的顶尖医学院（如约翰霍普金斯、梅奥诊所、香港中文大学、多伦多大学等）保持着长期且紧密的合作关系，都能间接反映出这所学校医学的教育质量特别高。临床医学(Medicine)作为高威大学(一所世界级的研究型大学)最王牌的专业，在这里医学生能够享受到无比丰富的资源。例如每年的暑假期间，有很多科研机会提供给医学生申请，无论是临床还是基础医学层面，都能为我们匹配到合适的导师！',
            photo: StudentPortraitDanShan
        },
        {
            name: '刘琴',
            title: '现就读于数字营销专业，硕士',
            quote: '目前为止，这门课最让我满意的地方是课程设置是紧跟行业前沿的。例如我们在最后的学习阶段接触到了关于元宇宙，Web3，NFT以及AR，VR的系统的知识以及行业应用案例，这些都是目前数字行业的人们专题，很多企业甚至是个人都想站在风口。在课业环节，我们被分成3-5人一组，现学现用的为企业提供与这些领域知识有关的方案。于我个人而言，这门课好在完美的弥补了我的专业短板。',
            photo: StudentPortraitQinLiu
        }
    ]

    return (
        <>
            <section className="text-gray-600 body-font">
                <div className="container px-5 py-24 mx-auto">
                    <h1 className="text-3xl font-medium title-font text-galway-maroon mb-12 text-center">学生感言</h1>
                    <div className="flex flex-wrap -m-4">
                        {
                            testimonials.map((item) => (
                                <Testimonial name={item.name} title={item.title} photo={item.photo} >{item.quote}</Testimonial>
                            ))
                        }
                    </div>
                </div>
            </section>
        </>

    )
}