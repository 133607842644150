import * as React from "react"

export interface SingleQuoteParams {
    name:  string
    title: string
    img:   string
    quote: string
}

export const SingleQuote = ( {name, title, img, quote}: SingleQuoteParams ) => {
    return (
        <>
            <div className="bg-gray-100 pt-24 pb-12">
                <figure className="flex flex-row mx-auto max-w-5xl">
                    <div className="flex flex-col m-6">
                        <img className="w-48 mb-4 rounded-full"
                             src={img} alt={name}/>
                        <figcaption className="font-medium">
                            <div className="text-galway-maroon">
                                {name}
                            </div>
                            <div className="text-slate-700">
                                {title}
                            </div>
                        </figcaption>
                    </div>
                    <div className="prose prose-slate max-w-3xl">
                        <blockquote>
                            <p className="text-gray-800 text-lg font-medium">
                                {quote}
                            </p>
                        </blockquote>
                    </div>
                </figure>
            </div>
        </>
    )
}