import * as React from 'react'
import * as SolidIcons   from '@heroicons/react/24/solid';
import * as OutlineIcons from '@heroicons/react/24/outline';

interface Props {
    icon: string;
    color?: string;
    size?: number;
    outline?: boolean;
}

export const HeroIcon = (props: Props): JSX.Element => {
    const { icon, color, size, outline = false } = props;

    const { ...icons } = outline ? OutlineIcons : SolidIcons;

    // @ts-ignore
    const Icon: JSX.Element = icons[icon];

    const classes = [
        `${color ? color : 'text-white'}`,
        size ? 'h-' + size : 'h-12',
    ];

    return (
        // @ts-ignore
        <Icon className={classes.join(' ')} />
    );
};