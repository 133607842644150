import * as React from "react"

import {HeroIcon} from "./HeroIcon"

import { Splide, SplideSlide } from '@splidejs/react-splide';

// Default theme
import '@splidejs/react-splide/css';

export interface Stat {
    key: string
    item: string
    icon: string
}

interface StatisticsParams {
    stats: Stat[]
}

const Statistics = ( {stats}: StatisticsParams ) => {
    return (
        <section className="body-font">
            <div className="container px-5 mx-auto">
                <Splide className="flex flex-wrap -m-4 text-center mx-auto p-10"
                    options={ {
                        type   : 'loop',
                        drag   : 'free',
                        autoWidth: true,
                        autoplay: true,
                    } }
                    aria-label="高威大学特色"
                >
                    {stats.map( (stat) => (
                        <SplideSlide className="p-4 lg:w-1/4 md:w-1/3 sm:w-1/2 w-full">
                            <div className="border-2 border-galway-maroon px-4 py-6 rounded-lg h-full">
                                <div className="mb-3 inline-block">
                                    <HeroIcon icon={stat.icon} color='text-galway-maroon' size={12} />
                                </div>
                                <h2 className="title-font font-medium text-3xl pb-2">{stat.key}</h2>
                                <p className="leading-relaxed">{stat.item}</p>
                            </div>
                        </SplideSlide>
                    ))}
                </Splide>
            </div>
        </section>
    );
}

export default Statistics