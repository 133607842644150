import * as React from "react"
import {SingleQuote} from "../single-quote";


export const PrincipalQuoteSection = () => {

    const name='Ciarán Ó hOgartaigh'
    const title='校长'
    const img='https://www.universityofgalway.ie/media/uncategorised/coh.jpg'
    const quote =   '感谢您选择高威大学（爱尔兰语：Ollscoil na Gaillimhe）开启学习之旅。\n' +
        '您应该已经注意到了我们的新名字和新身份。做为19世纪中期成立的我们，高威大学4个字简单清晰地说明了我们是一所大学，坐落于高威市。同时也清楚地表明，我们做为高威的一分子，通过我们始终坚持的尊重、开放、卓越和可持续发展的价值观为高威市提升积极影响的态度。\n' +
        '作为一所追求卓越的国际化大学，高威大学致力于培养优秀的学生和员工，在一流的设施设备中工作学习，开展前沿的教育和研究。\n ' +
        '高威是全球医疗技术、数据和IT中心，也是一座以文化和创意闻名的城市。我们希望你也能成为我们这个活力热情大家庭的一员，在世界领先学者的指导下学习研究，获得新的技能和知识，并将自己的激情和卓越一直保持下去。\n' +
        '我们很荣幸地邀请您加入高威大学的全球社区，我期待着你的到来！\n';

    return (
        <SingleQuote name={name} title={title} img={img} quote={quote} />
    )
}